import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { navigate } from 'react-big-calendar/lib/utils/constants'
// Navigate - to jsou vlastne konstanty v react-big-calendar/src/utils/constants.js
// kdyz budu menit datum pomoci datepickeru, tak se musi pouzit prave onNavigate ke zmene date.
// date je soucana hodnota kalendare. Urcuje viditelny rozsah view. Pokud je vynechano, 
// tak se pouzije vysledek fce getNow(), jinak soucasny datum.
import WeekPicker from '../WeekPicker';
import './CustomToolbar.css';
import moment from "moment"; // moment je udajne zastaraly, ale funguje, tak to zatim necham.
import { useState } from "react";

import { HonestWeekPicker } from "../HonestWeekPicker";

/*
Kdyz mam customToolbar, musi se nejak predefinovat i ty tlacitka next, back, today. 
Bylo to v diskusi. 
*/

/*
Tento kód představuje funkci ViewNamesGroup, která vytváří skupinu tlačítek pro zobrazení různých pohledů. 
Pravděpodobně je součástí nějakého React nebo podobného JavaScriptového projektu.

Zde je vysvětlení, co funkce dělá:

    Funkce ViewNamesGroup je komponenta, která přijímá několik vstupních props:
        views: viewNames: Pole pohledů, které bude zobrazeno ve skupině tlačítek.
        view: Aktuálně vybraný pohled.
        messages: Objekt s textovými zprávami nebo štítky pro každý pohled.
        onView: Funkce, která bude volána při kliknutí na tlačítko a předá jí název pohledu jako argument.

    Komponenta ViewNamesGroup mapuje přes pole viewNames (tj. views) a pro každý název pohledu vytváří tlačítko, 
    které bude reprezentovat daný pohled.

    Pro každé tlačítko se nastavuje následující:
        key: Každé tlačítko má unikátní klíč definovaný názvem pohledu.
        className: Třída "rbc-active" se přidá tlačítku, pokud je aktuální pohled (view) 
                  stejný jako název pohledu, což slouží k vizuálnímu zvýraznění aktivního pohledu.
        onClick: Při kliknutí na tlačítko se spustí funkce onView s názvem pohledu jako argumentem.

    Obsahem tlačítka je textová zpráva nebo štítek předaný v objektu messages pro odpovídající název pohledu.


Tato komponenta slouží k vytvoření seznamu tlačítek, 
kde každé tlačítko představuje jeden pohled a umožňuje uživateli 
přepínat mezi různými pohledy. Aktuálně vybraný pohled bude vizuálně zvýrazněn třídou 
"rbc-active". Když uživatel klikne na tlačítko, bude vyvolána funkce onView s názvem pohledu
jako argumentem, což umožní přizpůsobit chování aplikace v závislosti na vybraném pohledu.
*/
function ViewNamesGroup({ views: viewNames, view, messages, onView }) {
  return viewNames.map((name) => (
    <button
      type="button"
      key={name}
      className={clsx({ 'rbc-active': view === name })}
      onClick={() => onView(name)}
    >
      {messages[name]}
    </button>
  ))
}

// ViewNamesGroup je komponenta, funkce, ktera vraci pole buttonu.
// podle viewNames, ktere jsou predane z Calendaru. Napr. ja mam ted
// v Caledar nastaveno views={['week']} a tak se mi zobrazi jen jeden button.

// Na konci je definován propTypes pro zajištění typové kontroly vstupních props. 
// Tím se kontroluje, zda jsou správné typy předané jako vstup do komponenty.
// To by tady teoreticky byt nemuselo. Ale je to dobre, protoze to hlasi chyby, kdyz se neco pokazi.
ViewNamesGroup.propTypes = {
  messages: PropTypes.object,
  onView: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array,
}


// tyhle parametry se tam predavaji zvenci, nejak automaticky? 
// ty chlupaty zavorky - to uz jsem vedel, co znamena, ale ted to zas nevim. 
// je to objekt, ktery se tam predava, nebo je to jenom zapis?

export default function CustomToolbar({
  // date, // available, but not used here
  label,
  localizer: { messages },
  onNavigate,  // Tohle je z dokumentace a je to trochu matouci. Az to poradne pochopim, musim to zdokumentovat jinak. 
               // Callback fired when the date value changes. When included it is used to 'control' the date prop value. 
               // Mel by to byt onNavigate definovany v Calendaru.
  setCustomDate, // tohle je muj callback, ktery se zavola pri zmene data.
  onView,      // takze se sem predava z Calendaru.  
  view,        // view je ten aktualni pohled, ktery se zobrazi?
  views,
  date, // sem se dostane string, ale melo by to byt Date objekt. Ale kdyz to necham jako props, tak je to objekt. 
  fetchDataFromApi,
})

{
  // tohle se pouzije v tom weekpickeru. 
  const [objWeek, setObjWeek] = useState({
    date: new Date(), // na zacatku je v datepickeru aktualni datum, nastavene jako date. Po updatech tam bude to, co se nastavi jako objWeek.date.
//    date: null,   // kdyby tam bylo null, tak se zobrazi placeholder - Select a week. 
    dateFrom: moment(date).startOf('isoWeek').toDate(),   // dojet ten tutorial a nastavit to zobrazeni poradne. 
    dateTo: moment(date).endOf('isoWeek').toDate(),
    weekNumber: moment(date).isoWeek(),    
  });

  // tohle se pouzije v HonestWeekPickeru. Ten ted ve skutecnosti nepouzivam. Je vykomentovany. 
  const [week, setWeek] = useState({
    firstDay: new Date(),  // tady nepotrebuju zadnou default hodnotu. 
    lastDay: new Date(),  // to se stejne vyplni az v tom pickeru. 
  });

  const onChange = (week) => {
    console.log("CustomToolbar.js - onChange - week: ", week);
    setWeek(week);
    // tady z toho objektu week udelat fetch from api. 
    //const nextDate = moment(date).add(1, 'week').toDate();
    onNavigate(week.lastDay);
  };
  


  return (
    <div className="rbc-toolbar">

      {/* <HonestWeekPicker  /> */}
      {/* <h1>{convertDate(week.firstDay)}</h1>  */}

      <span className={clsx('rbc-btn-group', 'examples--custom-toolbarss')}>
      {/* slo by to tedy i takto; */}
      {/* <span className="rbc-btn-group examples--custom-toolbar">         */}
      {/* Tady se ty messages pouziji jen pro aria-label, takze nebudou ani videt, ale to nevadi. Treba to k necemu bude.  */}
      {/*  aria-label je atribut používaný v HTML a XML značkování, který slouží k poskytnutí alternativního textového popisu pro určitý prvek, který jinak nemá dostatečně výstižný textový obsah pro uživatele s omezeným vnímáním nebo asistivních technologií, jako jsou čtečky obrazovky.*/}
        <button // Previous week
          type="button"
          onClick={() => {            
            // TODO: refaktorizovat viz onChange ve WeekPicker.js 
            // Funguje, ale "divne". 
            // Jake datum ma byt ve weekpickeru zobrazene? Mel by tam byt ten rozsah. 
            // Navic, potrebuju updatovat weekNumber i pri prvnim zobrazeni kalendare. 
            // To se ted nedeje, ale vstupni datum ve weekpickeru je spravne. 
            // Je tam akualni den, ale weekNumber je prazdny. 
            const prevDate = moment(date).subtract(1, 'week').toDate();          
            const weekNumber = moment(prevDate).isoWeek();
            const dateFrom = moment(prevDate).startOf('isoWeek').toDate();  // tohle se da asi nahradit nejakymi temi funkcemi z date-fns
            const dateTo = moment(prevDate).endOf('isoWeek').toDate();      // import { addMonths, endOfWeek, startOfWeek, subMonths } from "date-fns";
            // setObjWeek({ // takhle to nefunguje. Asi proto, ze je to objekt?
            //   dateFrom,
            //   dateFrom,
            //   dateTo,
            //   weekNumber,
            // });
            setObjWeek({ // takhle to funguje. Nevim vlasne proc. TODO: Mrkni na https://blog.logrocket.com/using-react-usestate-object/
              date: dateFrom,
              dateFrom: dateFrom,
              dateTo: dateTo,
              weekNumber: weekNumber,
            });

            onNavigate(prevDate)}            
          }
          aria-label={messages.previous}
        >
          {messages.previous}
        </button>
        <button
          type="button" // Today
          onClick={() => {
            // FIXME: tohle jde urcite udelat lepe. Koukni na funkce v date-fns.
            const todayDate = new Date(); // TODAY
            const weekNumber = moment(todayDate).isoWeek();
            const dateFrom = moment(todayDate).startOf('isoWeek').toDate();  // tohle se da asi nahradit nejakymi temi funkcemi z date-fns
            const dateTo = moment(todayDate).endOf('isoWeek').toDate();      // import { addMonths, endOfWeek, startOfWeek, subMonths } from "date-fns";

            setObjWeek({ // takhle to funguje. Nevim vlasne proc. TODO: Mrkni na https://blog.logrocket.com/using-react-usestate-object/
              date: dateFrom,
              dateFrom: dateFrom,
              dateTo: dateTo,
              weekNumber: weekNumber,
            });


            onNavigate()}}
          aria-label={messages.today}
        >
          {messages.today}
        </button>
        <button
        // FIXME: TADY se to musi udelat stejne, jako je to u tlacitka Back. Next ted nefunguje.
        // rovnou to fixnout na ten HonestWeekPicker.
        // Udelat se to musi stejne, tedy liftovat stav nahoru, do spoleneho rodice.
          type="button" // Next week
          onClick={() => {
            const nextDate = moment(date).add(1, 'week').toDate();
            const weekNumber = moment(nextDate).isoWeek();
            const dateFrom = moment(nextDate).startOf('isoWeek').toDate();  // tohle se da asi nahradit nejakymi temi funkcemi z date-fns
            const dateTo = moment(nextDate).endOf('isoWeek').toDate();      // import { addMonths, endOfWeek, startOfWeek, subMonths } from "date-fns";

            setObjWeek({ // takhle to funguje. Nevim vlasne proc. TODO: Mrkni na https://blog.logrocket.com/using-react-usestate-object/
              date: dateFrom,
              dateFrom: dateFrom,
              dateTo: dateTo,
              weekNumber: weekNumber,
            });

            onNavigate(nextDate)}            
          }
          aria-label={messages.next}
        >
          {messages.next}
        </button>
      </span>
      {/* Tohle by se mohlo dat misto toho červenec 24 – 30 */}
      {/* Je tedy potreba zmenit to zobrazeni data a na klik do prislusneho dne pak nastavit prislusny range do calendare.  */}
      {/* To tam nejak mam udelane, nebo se to musi udelat pomoci tech onNeco udalosti? */}
      {/* upravit tu classu, bude potreba nejaka jina */}
      <span className="rbc-toolbar-label">  
        {/* <HonestWeekPicker onChange={onChange}
        // podobne, jako dole je objWeek liftovany nahoru, tak to musim udelat i tady.
        />       */}
        <WeekPicker 
          label={label}
          onNavigate={onNavigate} // to budu potrebovat k prekresleni kalendare. Kontroluje to date prop.
                                  // to uz je ale onNavigate predana z Calendaru do CustomToolbar, tedy ve skutecnosti handleNavigate                         
          objWeek={objWeek}       // tyhle mam liftovane, kvuli update stavu weekPickeru tlacitky odsud.  
          setObjWeek={setObjWeek}      // ale moc to nefunguje. Back ano, next ne. Tam se ty tydny meni nejak spatne.                                       
        />                       
      </span>
      {/* <span className="rbc-toolbar-label">{label}</span> */}
       {/* clsx - to je nejaka knihovna, ktera umi pridavat tridy podle podminek */}
       {/* Ale tady to dela jen concatenaci stringu. */}

      {/* Prepinac pohledu tady nechci, alespon zatim. Stejne by tam bylo jen week.  */}
      {/* <span className="rbc-btn-group">
        <ViewNamesGroup
          view={view}
          views={views}
          messages={messages}
          onView={onView}
        />
      </span> */}
    </div>
  )
}
// Tohle je jenom pro kontrolu typu vstupnich parametru. ??
CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  localizer: PropTypes.object,
  messages: PropTypes.object,
  onNavigate: PropTypes.func,
  onView: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array,
}