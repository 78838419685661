//npm install react-datepicker --save
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

// npm install rsuite
// React Suite is a library of React components, designed for middle platform and back-end products
import DatePicker from 'rsuite/DatePicker';
import 'rsuite/dist/rsuite.css';
import './WeekPicker.css';
import moment from 'moment';
import "moment/locale/cs";
//import cs from 'rsuite/locales/cs';

import { useState } from "react";
import enGB from 'date-fns/locale/en-GB';

// podle
// https://www.youtube.com/watch?v=47NNxWWMj_4
const WeekPicker = ({label, onNavigate, objWeek, setObjWeek}) => {  // tady mohu pouzit props, nebo rovnou label, kdyz to takto destrukturuju.
//const WeekPicker = (props) => {
  //console.log("WeekPicker props: ", props);
                                   // v props objektu bych mel vsechno a musel bych na label odkazovat pres props.label.  
  // stav pro uschovani week infos. 
  // pokud chci menit weekNumber z komponenty CustomToolbar, kdyz delam next a prev, tak musim ten state vytahnout nahoru. 
  // Staci asi do komponenty CustomToolbar. Ale 
  
    // const [objWeek, setObjWeek] = useState({
    //   date: new Date(),
    //   dateFrom: null,
    //   dateTo: null,
    //   weekNumber: null,
      
    // });
    
    // Co se stane, kdyz se zmeni datum v datepickeru. 
    // identicky, kdyz se zmeni datum pomoci tlacitek next a prev,
    // musim updatovat stav datepickerru. Tedy objWeek.date a objWeek.weekNumber
    // V customToolbar se pro ty tlacitka pouziva to onNavigate, takze by to slo 
    // udelat v nem. Pak by se to predalo pres props. Jenze, to by se zmenilo 
    // volani i tady. Lepsi bude udelat to nejdriv "naivne" a pak to refaktorizovat.      
    const onChange = (date) => {
      // potrebujeme vypociat week number a dateFrom a dateTo
      // dateFrom je pondeli, dateTo je nedele
      // date je aktualni datum, ktere je vybrano v datepickeru
      // pouzijeme knihovnu moment.js, jako ve zbytku projektu. 
      const weekNumber = moment(date).isoWeek();
      const dateFrom = moment(date).startOf('isoWeek').toDate(); // pondeli 
      const dateTo = moment(date).endOf('isoWeek').toDate();    // nedele
      setObjWeek({
        date,
        dateFrom,
        dateTo,
        weekNumber,
      });
      console.log("onChange(date) ", new Date(date));
      let newDate = new Date(date);
      onNavigate(newDate);
    }


    const renderValue = (date) => {
      const weekNumber = moment(date).isoWeek();
      const year = moment(date).year();
      // TODO dat sem pripadne i dateFrom a dateTo, nebo ten label, kter
      return `W${weekNumber}, ${year}`;
      //return `${label} ${weekNumber}, ${year}`;
  }


    //debugger;
    return (
      <div className='WeekPicker'>
      <DatePicker className="form-controlXX" // potreba udelat vlastni classu. 
        placeholder="Select a week"
        isoWeek   // zajisti, ze se bude zobrazovat tyden od pondeli do nedele
        showWeekNumbers // zobrazi cislo tydne
        value={objWeek.date} 
        onChange = {onChange}     
        renderValue = {renderValue}  
        ////locale={cs}      
      />
      
      <div className="WeekInfos">
        <div>
          <span>Týden číslo: </span>
          <span>{objWeek?.weekNumber}   </span>          
        </div>
          <span>{label} </span>
        <div>

        </div>

      </div>

      </div>
    );
  };



export default WeekPicker;
