import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './DeleteConfirmationModal.css';
import moment from "moment";

//const DeleteConfirmationModal = ({show, onHide, onConfirmDelete, closeParent}) => {
const DeleteConfirmationModal = (props) => {  

  return (
    <div>
      <Modal show={props.show} 
             onHide={props.handleCancelDelete} 
             centered             
             dialogClassName={'delete-modal'}
             size="lg"
             backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this record?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="danger" onClick={props.onConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModal;