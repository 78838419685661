//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.0.2/css/bootstrap.min.css'
import React from "react";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';
import { Button, Container, Row, Col } from 'react-bootstrap';

import AsyncSelectPatient from "../AsyncSelectPatient";
import AsyncSelectDiagnosis from "../AsyncSelectDiagnosis";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
// import AddPationModal from "./addpatient";
import moment from "moment";

const axios = require('axios').default;

class DraggableModalDialog extends React.Component {
  render() {
      return <Draggable handle=".modal-title"><ModalDialog {...this.props} /> 
 </Draggable>
  }
}

const AddPatientForm = (props) => {

    const [items, setItems] = React.useState([]);
    const [inputValue, setValue] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState(null);

    const [asyncTest, setAsyncTest] = React.useState([]);


    // handle input change event
    const handleInputChange = value => {
      setValue(value);
    };

    return(
    <Form autoComplete="off">
{/* ============== select patient ================ */}
    <Form.Group className="mb-3" controlId="patient-strednijmeno">
      <Form.Label><b>Search by surname</b></Form.Label>
      {/* Musim do async selectu predat funkci z index, protoze se pres ni nastavuji
        FormData, ktera ovlivnuji zobrazeni toho modalu. 
      */}
      <AsyncSelectPatient handleSelect={props.handleAsyncSelect}/>
      {/* Do props bych nejak musel predat, ze se jedna o surname, napriklad.  */}
    </Form.Group>
{/* =============== select diagnosis ============== */}
{/* Ty controlId vlastne nepotrebuju, protoze hodnoty predavam pres stavove promenne */}
{/* ktere nastavuji v handlerech jednotlivych prvku.  */}
    <Form.Group className="mb-3" controlId="diagnosis">
      <Form.Label><b>Search diagnosis</b></Form.Label>
      {/* Musim do async selectu predat funkci z index, protoze se pres ni nastavuji
        FormData, ktera ovlivnuji zobrazeni toho modalu. 
      */}
      <AsyncSelectDiagnosis handleSelectDiagnosis={props.handleAsyncSelectDiagnosis}/>
      {/* Do props bych nejak musel predat, ze se jedna o surname, napriklad.  */}
    </Form.Group>

    <Form.Group className="mb-3" controlId="patient-given_name">
      <Form.Label>Given Name</Form.Label>
      <Form.Control name="given_name" type="text" placeholder="placeholder" 
        onChange={(e) => props.handleOnChange(e,"patient")}
        value={props.FormData.given_name}/>
    </Form.Group>
  


    <Form.Group className="mb-3" controlId="patient-surname">
      <Form.Label>Surname</Form.Label>
      <Form.Control         
        name="surname" 
        type="text" 
        placeholder="" 
        // onChange={props.handleOnChange}
        onChange={(e) => props.handleOnChange(e,"patient")}
        value={props.FormData.surname} />        
    </Form.Group>
              
    <Form.Group className="mb-3" controlId="patient-birth_number">
      <Form.Label>Birth Number (partial)</Form.Label>
      <Form.Control name="birth_number" type="txt" placeholder="" 
      onChange={(e) => props.handleOnChange(e,"patient")}
      value={props.FormData.birth_number}/>
    </Form.Group>
        
  <Form.Group className="mb-3" controlId="patient-mail">
      <Form.Label>E-mail</Form.Label>
      <Form.Control name="mail" type="email" placeholder="Enter email" 
      onChange={(e) => props.handleOnChange(e,"patient")}
      value={props.FormData.mail}/>
  </Form.Group>
      
  <Form.Group className="mb-3" controlId="patient-phone">
      <Form.Label>Phone</Form.Label>
      <Form.Control name="phone" type="txt" placeholder="" 
      onChange={(e) => props.handleOnChange(e,"patient")}
      value={props.FormData.phone}/>
  </Form.Group>
    
  <Form.Group className="mb-3" controlId="patient-notes">
      <Form.Label>Patient notes</Form.Label>
      <Form.Control  name="patient_notes" rows={3} as="textarea" placeholder="Nejaka poznamka ohledne pacienta" 
      onChange={(e) => props.handleOnChange(e,"patient")}
      value={props.FormData.patient_notes}/>
      <Form.Text className="text-muted">
        Place for additional information, regarding patient
      </Form.Text>
  </Form.Group>

  <Form.Group className="mb-3" controlId="surgery-notes">
      <Form.Label>Surgery notes</Form.Label>
      <Form.Control name="surgery_notes" rows={3} as="textarea" placeholder="Poznamka k operaci" 
      onChange={(e) => props.handleOnChange(e,"surgery")}
      value={props.FormData.surgery_notes}/>
        <Form.Text className="text-muted">
        Place for additional information, regarding surgery
      </Form.Text>
  </Form.Group>

  <Form.Group className="mb-3" controlId="checkboxes">
    <Form.Check type="checkbox" label="Examination/Vyšetření" name="examination_only" 
        onChange={(e) => props.handleCheckbox(e)}           
        defaultChecked={props.FormData.examination_only}         
    />
    <Form.Check type="checkbox" label="Gliolan" name="gliolan" 
        onChange={(e) => props.handleCheckbox(e)}  
        defaultChecked={props.FormData.gliolan}
    />
  </Form.Group>

    <Form.Control name="id" type="hidden" 
      onChange={props.handleOnChange}
      value={props.FormData.id} />

    <Form.Control name="dtype_id" type="hidden" 
      onChange={props.handleOnChange}
      value={props.FormData.dtype_id} />

    <Form.Control name="patient_id" type="hidden" 
      onChange={props.handleOnChange}
      value={props.FormData.patient_id} />

    <Form.Control name="surgeon_id" type="hidden" 
      onChange={props.handleOnChange}
      value={props.FormData.surgeon_id} />

  </Form>       
   
    );
  }
  
/*
* Stav, ktery ridi zobrazovani modalu se nastavuje pres parent komponentu <App /> 
* (ale mozna zbytecne, v confirmdelete.js to mam jinak - ne, ta udalost, ktera rika, zda to zobrazit, nebo ne, ta je z kalendare. )
* Tady by jeste na onClick Save musel nastat ten axios, ktery to ulozi do db. 
* 
* https://www.pluralsight.com/guides/working-with-bootstraps-modals-react
*/  
const AddPatientModal = (props) => {
    const saveFormDataDataHandler = (enteredFormData) => {
      console.log("AddPatientModal evaluated");
      console.log("enteredFormData:");
      console.log(enteredFormData);
    }

    const [showDeleteModal, setShowModal] = useState(false);

    const handleDeleteClick = () => {
      setShowModal(true);
    };

    const handleConfirmDelete = async () => {   

      const result = await axios.delete('/apisurgery' + '/' + props.FormData.id)          
      setShowModal(false); 
      props.handleClose(); // tohle je zavreni modalu.
      
      // TODO: projit vsechna pouziti fetchDataFromApi a zjistit, jak se ta data nastavuji v jednotlivych pripadech.
      // Byl tu pouzity FormData.date, misto props.FormData.date. FormData je pochopitelne undefined. 
      //console.log("FormData.date: ", props.FormData.date);      
      let from_date = moment(props.FormData.date).startOf('week').toISOString().split('T')[0]; 
      var to_date = moment(props.FormData.date).endOf('week').toISOString().split('T')[0];        
      
      props.fetchDataFromApi(from_date, to_date);
    };
  
    const handleCancelDelete = () => {
      setShowModal(false);
    };

    return(
    <div>     
    {/* Nize uvedene umozni, ze form pujde zavrit kliknutim kamkoli mimo modal.
      <Modal show={props.isOpen} onHide={props.handleClose} dialogClassName={'primaryModal'} >
      <Modal.Header closeButton>
    */}
    <Modal 
           show={props.isOpen}  
           dialogClassName={'primaryModal patientModal'} 
           dialogAs={DraggableModalDialog} 
           
           >
      <Modal.Header>        
        <Modal.Title>Add Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        {/* Z nize uvedeneho formulare potrebuju dostat data! Zase nejak pres props? */}
        {/* Jeste tam musim pridat tlacitko smazat. Co se stane, kdyby sestra vymazala
            vsechna data z formulare - je to ekvivalent smazani?
        */}
        <AddPatientForm 
          FormData={props.FormData}         
          handleOnChange={props.handleOnChange}
          handleAsyncSelect={props.handleAsyncSelect}
          handleAsyncSelectDiagnosis={props.handleAsyncSelectDiagnosis}
          handleCheckbox={props.handleCheckbox}
          //onSaveFormData={saveFormDataDataHandler}
          />
      </Modal.Body>
      <Modal.Footer>
      <Container>
        <Row>
        <Col xs={6}>
           <Button  variant="danger" onClick={handleDeleteClick}>
           {/* </Button><Button  variant="danger" onClick={props.handleDelete}> */}
            Delete Record
          </Button>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={props.handleSubmit}>
            Save Changes
          </Button>
        </Col>
      </Row>
    </Container>
  
    
      
      </Modal.Footer>
    </Modal> 

        
    <DeleteConfirmationModal
        FormData={props.FormData}
        show={showDeleteModal}
        onHide={handleCancelDelete}
        onConfirmDelete={handleConfirmDelete}
        closeParent={props.handleClose}
        fetchDataFromApi={props.fetchDataFromApi}
    />
    </div>
    );
  }


export default AddPatientModal;
