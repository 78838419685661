// mock settings
// v budoucnu by se toto pole melo vracet pres api z backendu. 
// v podstate to nemusi byt pole, ale staci jeden objekt.

export default 
    {
        weekends: true,  // TODO
        holidays: true,  // TODO
        fontSize: 'x-large',
        dayLayoutAlgorithm: 'no-overlap',
        calendarStep: 10,
        calendarTimeslots: 4,

    }
