import axios from 'axios';
import AsyncCreatable from 'react-select/async-creatable';
import { useState } from 'react';

const AsyncSelectPatient = (props) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setValue] = useState('');
   // const [items, setItems] = useState([]);

    // lze konvertovat na async function, ale nevidim duvod. 
    const fetchData = (value) => {
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        // Sem jeste pripadne validation token. Ale ten je potreba hlavne pro create/update dat.
        // pro ziskani dat neni tolik potreba.
        console.log("fetch value: "+ value);
        return axios.get('/apipatient/search?PatientSearch[surname]='+value)
               .then((result) => {
                  const res = result.data;
                  console.log("result z axios")
                  console.log(res);
                  return res;
               })        
    }
    
    const handleChange = (value) => {
        console.log("onChange")
        console.log(value);
        setSelectedValue(value);
        props.handleSelect(value);
    }

    const handleInputChange = (value) => {
        console.log("onInputChange: " + value)
    }

    return(
        <AsyncCreatable 
        //backspaceRemovesValue
        loadOptions={fetchData}
        value={selectedValue}
        onInputChange={handleInputChange}  // spousti se pri kliku do selectoru
        onChange={handleChange}  
        //onChange={props.handleSelect}  
        placeholder={"Select..."}
        noOptionsMessage={() => {return ("No options...")}}
        />
    )
}


export default AsyncSelectPatient;

