/*jshint esversion: 9 */

import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";


const axios = require('axios').default;

import DndCalendar from "./components/DndCalendar";
//import 'moment/locale/cs';   // ceske nazvy mesicu a casovych udaju

const App = () => {

  // FIXME: tenhle token odpovida auth_key uzivatele krepsjan, proto pak blbne to prihlasovani/odhlasovani. 
  // Tohle se pouzije pro vsechny volani axios, takze u jednotlivych GET/POST/PUT uz to neni potreba
  const validToken = document.getElementById('my_token').innerText; 
  // Tohle nebude fungovat bez te stranky, v samostatnem runu. Proto jsem vyse mel ten validToken nastaveny 
  // na tvrdo. FIXME to se musi doresit, tohle neni spravny zpusob predavani toho tokenu, i kdyz to funguje. 
  //const validToken = document.getElementById('my_token').innerText;
    
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + validToken
    
  return (
    <div>
      <DndCalendar />
    </div>
  );

};

ReactDOM.render(<App />, document.getElementById("admission-book"));
