
// kdyz to definuju jako objekt, jsou to vsechno property a mohu pouzit operator in
export default 
    {
        '1-1': 'Nový rok, Den obnovy samostatného českého státu',        
        '5-1': 'Svátek práce',       
        '5-8': 'Den vítězství',
        '7-5': 'Den slovanských věrozvěstů Cyrila a Metoděje',
        '7-6': 'Den upálení mistra Jana Husa',
        '9-28': 'Den české státnosti',
        '10-28': 'Den vzniku samostatného československého státu',
        '11-17': 'Den boje za svobodu a demokracii',
        '12-24': 'Štědrý den',
        '12-25': '1. svátek vánoční',
        '12-26': '2. svátek vánoční' 
        
    }
