import React, { useEffect, useState } from "react";
import {
  Calendar,
  Views,
  momentLocalizer,
  ToolbarProps,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import PropTypes from "prop-types";
import moment from "moment"; // moment je udajne zastaraly, ale funguje, tak to zatim necham.
import "moment-timezone"; // musi byt instalovatny zvlast jako nmp install moment-timezone
// ucelem je, abych si mohl zmenit timezonu na jinou, nez je default v browseru. V mem pripade
// budu chcit pouzit UTC
// Hele, kdyz tam dam tohle, tak odpadne ta chyba s letnim casem.
moment.tz.setDefault("UTC");
import AddPatientModal from '../AddPatientModal';
import "moment/locale/cs";

import MyEvent from "../MyEvent";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

//import "./events.css";
import "./DndCalendar.css";
import settings from "../../settings";
import CustomToolbar from "../CustomToolbar";
import holidays from "../../holidays";
import { easter } from "date-easter"; // je to v curly braces, protoze date-easter nema default export. Musi se importovat presne to jmeno.

// var bevents = [     {
//   title: "bevent",
//  // allDay: true,
//   start: new Date(2023, 3, 13, 1), // backgroud od 6 do 16
//   end: new Date(2023, 3, 13, 23),
// }
// ]
import dates from "react-big-calendar/lib/utils/dates";
//import localizer from "react-big-calendar/lib/localizer";
const axios = require("axios").default;

//BigCalendar.momentLocalizer(moment);  // localizace dat a jmen mesicu.
const localizer = momentLocalizer(moment);

// tohle je ta samotna komponenta, ktera se bude renderovat.
const DragAndDropCalendar = withDragAndDrop(Calendar);

// tohle je funkce, ktera bude renderovat tu komponentu DragAndDropCalendar.
// mela by byt ekvivalentem komponenty MyCalendar, ktera je nize.
//export default function DndCalendar({localizer}) {

function DndCalendar(props) {
  
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const [backgroundEvents, setBackgroundEvents] = useState([]);
  const [apiEvents, setApiEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date()); // tady je ta chyba? Je tam porad aktualni datum?

  // FormData je stav liftovany up z komponenty AddPatientModal.
  const [FormData, setFormData] = useState(
    {
      id: "",
      dtype_id: "",
      patient_id: "",
      surgeon_id: "",
      given_name: "z",
      surname: "z",
      birth_number: "z",
      mail:"z",
      phone:"z",
      patient_notes:"z",
      surgery_notes:"z",    
      start: "z",
      end: "z",
      date: "",
      gliolan: false,
      examination_only: false,
    });

  const dayLayoutAlgorithm = "no-overlap";

  const eventRenderProp = (event, start, end, isSelected) => {
    let result = {
      className: "rbc-event--" + event.type,
      //style: "Objectco"  // tady by se predepsal ten style primo, ve formatu js objektu.
      // asi by se to mohlo hodit, kdyz bych ho chtel dynamicky zkonstruovat, ale to nepotrebuju
      // staci mi ta classa.
    };
    // Pouziti:
    //if event.type: "detsky-volny"
    //customClass = `${className} rbc-event--${event.type}`
    return result;
  };

  const doubleClicker = (event) => {
    console.log("doubleclick nastal: ");
    console.log(event); // fajn, v eventu mam vsechno o tom slotu.
  };

  // potrebuju vlastni nastaveni currentDate, protoze ho menim zvenku, z komponenty WeekPicker  
  // ale ty parametry jsou dany tim, ze to obsluhuje onNavigate. 
  // signatura je onNavigate(date, view, action) ??
  const handleNavigate = (newDate) => {
    
    console.log("===============================================")
    console.log("handleNavigate  newDate: ", newDate);
    console.log("handleNavigate currentDate: ", currentDate); // currentDate je state komponenty. Takze je videt i zde uvnitr. 
    // potrebuju pred tim, zavolat fetchDataFromApi, ktere mi vrati eventy pro ten tyden.
    // z toho newDate ziskat from_date a to_date a zavolat fetchDataFromApi.
    // To je uz opakujici se pattern. 
    // TODO: refaktorizovat to do nejake funkce, ktera bude brat newDate a vracet from_date a to_date.
    // nebo vcetne toho fetchDataFromApi.
    let from_date = moment(newDate).startOf('week').toISOString().split('T')[0]; 
    let to_date = moment(newDate).endOf('week').toISOString().split('T')[0];    
    fetchDataFromApi(from_date, to_date); 
    setCurrentDate(newDate)
  }

  // predava se jako handleClose do AddPatientModal
  // submit by to mel taky zavrit, ale tohle by melo jen vynulovat FormData, pro jistotu. 
  const handleCloseAddPatientModal = (event, data) => {
    // FIXME: to nastaveni FormData je potreba nejak ucesat a mozna zjednodusit.
    setFormData({   
      id: "",
      dtype_id: "",
      patient_id: "",
      surgeon_id: "",
      given_name: "",
      surname: "",
      birth_number: "",
      mail: "",
      phone: "",
      patient_notes: "",
      surgery_notes: "",
      start: "",   // start/end musi existovat v kalendari vzdycky.
      end: "",
      date: "",
      type: "",   // ten by taky mel existovat vzdycky. 
      gliolan: false,  // tohle mi nefunguje, bud to je true, nebo false a nejde to po zaskrtnuti zmenit. 
      examination_only: false,
    });   
    setShowAddPatientModal(false);
  }

    // tohle se zavola UVNITR komponenty Calendar, 
  // kde je k dispozici ten event a tim se vyplni formData
  // 
  // TODO: doplnit kontroly tak, aby nevyskakoval modal v pripade,
  // ze se klikne na all-day event, ktery oznacuje svatek. 
  // TOHLE presunout do komponenty DndCalendar. 
  // Ale musim vymyslet neco s tim predavanim formData.
  // Nicmene to jde spravnym smerem, zapouzdrit vsechno do komponent, 
  // co nejblize k tomu, kde se to pouziva.
  const handleShowAddPatientModal = (event) => 
  {
    console.log("FormData:")
    console.log(FormData)
    console.log("Event:")
    console.log(event)
    // Ty prazdny stringy jsou tam kvuli tomu, aby hodnota nebyla undefined - pak by se
    // controll menil z uncontrolled  na controlled a zpatky, coz nechci. 
    setFormData({      
        id: event.id || "",
        dtype_id: event.dtype_id || "",
        patient_id: event.patient_id || "",
        surgeon_id: event.surgeon_id || "",
        given_name: event.given_name || "",
        surname: event.surname || "",
        birth_number: event.birth_number || "",
        mail: event.mail || "",
        phone: event.phone || "",
        patient_notes: event.patient_notes || "",
        surgery_notes: event.surgery_notes || "",
        start: event.start.toISOString().slice(0,19).split('T')[1],   // start/end musi existovat v kalendari vzdycky.
        end: event.end.toISOString().slice(0,19).split('T')[1],
        date: event.start.toISOString().split('T')[0],
        type: event.type,   // ten by taky mel existovat vzdycky. 
        gliolan: event.gliolan,  
        examination_only: event.examination_only,
      });    
    if(event.type == 'svatek'){  // magic - hodit do konstanty
      alert(event.surgery_notes);  // alert nahradit nejakym lepsim modalem. 
      return;
    }
    setShowAddPatientModal(true);
  }

  // ====================================================================================
  // Submit celeho formulare, vola ruzna api, podle toho, zda jde o novy event, nebo update
  // Predava se jako handleSubmit do AddPatientModal. 
  // V liftovanym stavu FormData by mely byt pripraveny data. 
  const handleSubmitAddPatientModal = (event) =>
  {
    console.log("handleSubmitAddPatientModal");
    console.log(event);
    console.log(FormData);
    // kdyz jsou pole nevyplnena, tak je ve FormData jako hodnota Unndefined.
    // Na to bacha, hazi to pak chyby o controlles/uncontrolled komponente.
      
    // tohle url (jake? proc to tu neni?) je vlastne default, takze pro ten post je predgenerovany ovladac
    // ktery se v backendu pouzije. Pokud to chci rozsirit, napr. o informace, ktery
    // prihlaseny uzivatel zapis udelal, nebo zaznamenat zmeny, pak kontroler musim
    // prepsat a doplnit o tyto moznosti. Treba vcetne toho, ze bude vracet rovnou 
    // ty eventy.

    // ====================================================================================
    // Existujici surgery.... delame update, use PUT - vyuzije asi defaultni kontroler
    // v parametru event ale je synteticky event, ten nevyuzijeme, dal by se mozna pouzit, pokud by bylo 
    // potreba nejak modifikovat DOM, nebo resit, ktere tlacitko bylo stisknute apod.
    
    if (FormData.id != "") 
    { // Pozor, mohl se tam zmenit i pacient. Zda ma jit menit z modalu, nebo ne, to je asi otazka do settings.
      // Na posledni schuzi se to nedohodlo.  
      // Mam ve FormData stavovou promennou, zda se modifikoval i pacient
      // a zda ho mam tedy ulozit. To se uvnitr updateExistingEvent musi pouzit
      // console.log("id je neprazdne, updatujeme existujici event (surgery)")      
      // Tohle se vola vzdy, kdyz modifikuju slot, i predgenerovany. I ten uz ma svoje ID
      updateExistingEvent(); // Ale totez bych mel mit ve FormData?

    } else {
      // Eventy jsou vetsinou predgenerovane prazdne, jako sloty 
      // createNewEvent() se vola pouze v pripade, ze pridavam operaci do prazdneho mista. 
      //       
      createNewEvent(); // to zalozi pacose OK
    }
  }

  // ====================================================================================
  // FIXME!!! tohle je zbytecne komplikovany, s tim predavanim. 
  // tahle funkce se predavala jako handleOnChange do AddPatientModal a dale do PatientForm.
  // coz je asi zbytecne komplikovany. Definovat to rovnou v komponente AddPatientModal a bude klid. 
  // Vola se pri stisku klaves v input polickach formulare (bez naseptavace)
  // Protoze mam controlled componentu, budu zaroven pak predavat zase FormData do ni, 
  // a nastavovat ty value
  // To jmeno je trochu matouci.
  const handleOnChangeAddPatientModal = (e, type) =>
  {
    // e je syntetic event
    // target je napr. toto:
    // <input name="surname" placeholder="" type="text" id="patient-surname" class="form-control" value="Surname" data-com.bitwarden.browser.user-edited="yes"></input>
    // takze muzu udelat pak filter do ajaxu (teoreticky):
    // http://motol-demo.test/apipatient/pokus?PatientSearch[e.target.name]=e.target.value

    // kdyz je to z naseptavace, tak tam je jen pole s hodnotami, nikoli syntethicevent. 
    // ale naseptavac si ty FormData musi nastavovat sam
    console.log("Kontrola v handleOnChange")
    console.log(e.target)
    var save_patient = false;
    var save_surgery = false;
    const name  = e.target.name;  // to je jmeno komponenty. Musel bych tedy mit komponentu gliolan, s hodnotou true/false
    const value = e.target.value; // a jeji hodnota. 

    // Jenze, ja tam chci jen prohazovat hodnotu true/false, tj, kdyz byla true, nastav false a naopak. 

    // Tady bych mohl udelat modified, ale tohle reaguje na klavesy ve vsech polickach, tedy i tech s poznamkou k operaci.
    // musel bych ty ovladace mit dva, jeden pro zmeny v polickach operace a druhy pro zmenu v polickach pacienta. 
    // neco jako:
    // patient_modified: true
    // surgery_modifies: false
    // to by ovsem slo pomoci parametru te funkce. 
    //console.log(type);
    
    if(type == "patient") {
      save_patient = true;
    }
    if(type == "surgery") {
      save_surgery = true;
    }
    setFormData((prevState) => {
      return{...prevState,  [name]: value, save_patient, save_surgery}
    });         
  }

  // tyka se asynchronniho hledani pacienta
  const handleAsyncSelect = (data) => 
  {
     console.log("handleAsyncSelect val:");
     console.log(data)
    
    setFormData((prevState) => {
      return{...prevState,  
          patient_id: data.id,
          given_name: data.given_name,
          surname: data.surname,
          birth_number: data.birth_number,
          mail: data.mail,
          phone: data.phone,
          patient_notes: data.notes, // tady mozna ma byt jen notes - podle toho, co vraci api
          //dtype_id: data.dtype_id, // tohle je pridane. 
    
        }
    }); 
    console.log(data)

  }

  const handleAsyncSelectDiagnosis = (data) =>
  {
    // console.log("handleAsyncSelectDIAGNOSIS val:");
    // console.log(data)

    setFormData((prevState) => {
      return{...prevState,  
         dtype_id: data.dtype_id,
        }
    }); 


  }  

  // ============================================================================
  // potrebuju ten event, abych vedel, ktery chceckbox budu menit. 
  // Ale pozor. React predava syntetic event, ktery pote, co event handler skonci 
  // se nastavi na null. 
  // Pak to konci s chybou 
  // This synthetic event is reused for performance reasons. If you're seeing this, you're accessing the property `target` on a released/nullified synthetic event
  // Je to proto, ze event predany do handleru je pouzity v asynchronnim kontextu.
  //
  // Hlavne jde o to, ze ten setFormData je asynchronni, takze se pushne nekam na stack a vola se jich pak vic najednou, z performance duvodu. 
  // Takze v dobe, kdy se vola setFormData uz puvodni handler exitoval a ten event zaniknul.
  //
  // Resenim je priradit si to, co potrebuju, do lokalnich promennych a referencovat tyto promenne. 
  // Tak, jako to mam v handleOnChangeAddPatientModal (coz jsem asi castecne odnekud vykradl)
  // ============================================================================
  const handleCheckbox = (e) => {  
    const target = e.target;
    console.log("Budu menit hodnotu checkeru ve FormData")
    console.log("event je:")
    console.log(target);
    console.log(FormData);  //  
    setFormData((prevState) => {
      return{...prevState,
        [target.name] : target.checked,  // pouze prohodim hodnotu checkeru. Value je on/off asi, checked je true/false
      }
    });
    console.log(FormData);
  }

  // ====================================================================================  
  // abych věděl, ve kterém jsem týdnu, pro update events, budu potrebovat 
  // date jako stavovou promennou? 
  // TODO importnout z utils a tady vyhodit
  const fetchDataFromApi = (from_date, to_date) =>
  {
    console.log("fetchDataFromApi from_date: " + from_date + " to_date: " + to_date);
    axios.get('/apisurgery/fromDate/'+from_date+'/toDate/'+to_date) // tohle je promise, proto tam je to then. 
    .then(function (response) {
      //console.log("fetchDataFromApi: then response: ", response);
      // process results on success      
      var slots = response.data;
      
      for (let i = 0; i < slots.length; i++) {
        slots[i].start = moment.utc(slots[i].start).toDate();
        slots[i].end = moment.utc(slots[i].end).toDate();
      }            
      //setApiEvents(slots);  // nastavim api a pak to jeste zmodifikuju tim set holidays. 
      setHolidays(from_date, to_date, slots); // nemelo by se to jmenovat set, nenastavuje to stav.
    });
  }

  // ====================================================================================  
  // Nastavit svátky, jako background event a all-day-event
  // Ty bacground jsou samostatna promenna, ty all=day se musi pridat do apiEvents pomoci
  // toho operatoru spread. 
  // TODO importnout z utils a tady vyhodit
  const setHolidays = (from_date, to_date, slots) =>
  {
    let thiseaster = easter(new Date().getFullYear())
    let friday = thiseaster.month + '-' + (thiseaster.day - 2)
    let monday = thiseaster.month + '-' + (thiseaster.day + 1)

    var enddate = new Date(to_date)
    let bgEvents = [];
    //let allDayEvents = [];

    // smycka funguje!
    for (var d = new Date(from_date); d <= enddate; d.setDate(d.getDate() + 1)) {
      //console.log(d);
      let key = d.getMonth() + 1 + '-' + d.getDate()
      if (key in holidays) {
        // console.log("Skutecny start:" + new Date(2023, 3, 13, 2))
        // console.log("Skutecny end:" + new Date(2023, 3, 13, 24))
        bgEvents.push({          
          surgery_notes: holidays[key],
         // allDay: true,
          start: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 2), // backgroud od 6 do 16
          end: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 24),
        })

        slots.push({
          surgery_notes: holidays[key],
          allDay: true,
          start:  new Date(d.getFullYear(), d.getMonth(), d.getDate(), 2),
          end: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 24),
          type: "svatek",
        })
                
      }
      if (key == friday) {
        //console.log("Dnes je Velký pátek")
        // tady bude podobna konstrukce jako u svatku - bevents a allday
        bgEvents.push({          
          surgery_notes: "Velký pátek",
         // allDay: true,
          start: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 2), // backgroud od 6 do 16
          end: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 24),
        })
        slots.push({
          //title: "Velký pátek",
          surgery_notes: "Velký pátek",
          allDay: true,
          start:  new Date(d.getFullYear(), d.getMonth(), d.getDate(), 2),
          end: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 24),
          type: "svatek",
        })

      }
      if (key == monday) {
        //console.log("Dnes je Velikonoční pondělí")
        // tady bude podobna konstrukce jako u svatku - bevents a allday
        // ale tady to zase nejak nefunguje. 
        bgEvents.push({          
          surgery_notes: "Velikonoční pondělí",
         // allDay: true,
          start: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 2), // backgroud od 6 do 16
          end: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 24),
        })
        slots.push({
          surgery_notes: "Velikonoční pondělí",
          allDay: true,
          start:  new Date(d.getFullYear(), d.getMonth(), d.getDate(), 2),
          end: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 24),
          type: "svatek",
        })
      }
    }  // for loop
    setBackgroundEvents(bgEvents) // funguje dobre
    setApiEvents(slots)
    
    
     //newEvents.push(alldayEvent)  // doplnit o novy event. 
        // asi to nefunguje a fungovat takhle nebude. Shit.         
        //https://stackoverflow.com/questions/54867616/console-log-the-state-after-using-usestate-doesnt-return-the-current-value
        // vzhledem k asynchronni povaze tech volani by asi bylo jednodussi to poslat hotove uz z backendu. 
        // alespon ten all-day event. 
    
    //setApiEvents(newEvents)
        
    
  }

  // =======================================================================
  // Souvisi s tim, jak se v apisurgeries get potom kombinuji jiz v databazi
  // existujici operace a prazdne sloty, generovane podle casoveho planu pro 
  // vsechny dny. Puvodne to bylo jen tak, ze je bud prazdny slot, nebo ve 
  // stejnem case a datu existuje zadana operace v databazi. Pak se misto generovaneho
  // slotu pouzila ta udalost z databaze, vlozila se do events a vratilo se
  // pole events na cely tyden pro rendering kalendare. 
  // Kdyz se maji eventy presouvat, tak se musi ten presunuty event rovnez ulozit
  // do databaze. A to se muze rozpadnout na nekolik pripadu
  // 1) Uz tam je operace - to znamena, ze musim u existujiciho zaznamu s nejakym id updatovat start a end
  // 2) Movuju prazdny event - proc bych to delal? Treba proto, ze si chci uvolnit misto. 
  //    pak bych mel ulozit nejen ten prazdny event jinam, ale nejak zajistit, ze se mi pri generovani
  //    to prazdne misto nezaplni vygenerovanym slotem. NEBO, prazdny eventy movovat nepujdou. 
  // 3) ???
  // Nejprve ale musim spravne udelat ten presun/update EXISTUJICI operace.
  // A pak vkladani nove operace, (nebo prazdneho slotu) mezi stavajici volne. 
  // To mi porad vede na to, ulozit vse do databaze pri renderovani tydne. (Nebo pri updatu tady? Kdyz 
  // modifikuju cely seznam events? Tak ho projit a vse ulozit?) Potom by se generovalo jen tehdy,
  // kdyz seznam slotu z databaze je prazdny. Pokud neni, byly by v nem jak zaplnene, tak i prazdne sloty.
  // Pri zmene casovych slotu by se stavajici nezmenily (jsou uz v databazi), ale nove do budoucna ano.
  // Kdyby to nevyhovovalo, tak si to mohou upravit rucne. 

  const handleCalendarMoveEvents = async ({ event, start, end }) =>
  {
    const idx = apiEvents.indexOf(event)
    // tady uz teda mam ten updatovany event. Start a end se zmenily. 
    // Budu muset updatovat tento event, tedy ho soupnout zpatky do db. To ale delam vsechno v te vrchni komponente. 
    const updatedEvent = { ...event, start, end };  // tahle syntaxe je vysvetlena v kurzu Packt. Je to ten spread
                                                    // tj. vsechny polozky se zachovaji, a zmeni se jen ten start a end.

    const nextEvents = [...apiEvents];  // zkopiruju vsechny eventy
    nextEvents.splice(idx, 1, updatedEvent); // nahradit 
    // ten puvodni se musi opravdu smazat. Na stejne misto vlozim 
    // ten updatovany, ktery ma ale jiny start a end. 
    // ted bych potreboval posunout ty ostatni o ten casovy rozdil.
    setApiEvents(nextEvents);   // timhle jen updatuju komponentu. Mohl bych tim i ukladat?
    const ev_date = moment(start).toISOString().split('T')[0];
    const ev_start = moment(start).toISOString().split('T')[1].split('.')[0];
    const ev_end = moment(end).toISOString().split('T')[1].split('.')[0];

    if (event.id) {
      const surgeryParameters = {
        id: event.id,
        date: ev_date,
        start: ev_start,
        end: ev_end,  
      // nic dalsiho se menit nebude,//onHide(); takze to nemusim predavat.    
      }
      // tady kdyz to hodi chybu, tak v konsoli to zatroubi
      // Uncaught (in promise) Error: Request failed with status code 422
      // Pozor, tady je PUT, nikoli post. Postem ty veci vytvarim, putem updatuji.   
      const result = await axios.put('/apisurgery/' + event.id, 
                surgeryParameters,
      )
      return result;
    } // endof if(event.id)
    return;
  }

  // ====================================================================================
  // Vola se pri prechodu mezi tydny (zmene range) v kalendari. 
  // V parametru date je seznam vsech dni (dat) v danem tydnu. 
  // TODO: refaktorizace
  const handleCalendarRangeChange = (date) =>
  {
    console.log("handleCalendarRangeChange", date)
    let from_date = date[0].toISOString().split('T')[0]; 
    let to_date = date[date.length -1].toISOString().split('T')[0];
    
    axios.get('/apisurgery/fromDate/'+from_date+'/toDate/'+to_date, 
    )
    .then(function (response) {
      let slots = response.data;
      // FIXME: tady to blbne pri tom prechodu na letni cas?
      // Protoze z API to dostavam spravne. Ale tady se pak pouziva ten moment.utc
      // a prepisuje se ten zacatek a konec slotu. 
      for (let i = 0; i < slots.length; i++) { 
        // Tohle musi byt takhle, tedy s tou konverzi do utc, aby tam nelezl
        // ten letni cas. Viz radek 
        // moment.tz.setDefault('UTC'); v Calendar.js
        slots[i].start = moment.utc(slots[i].start+'Z').toDate();
        //slots[i].start = new Date(slots[i].start+'Z');
        slots[i].end = moment.utc(slots[i].end+'Z').toDate();
        // slots[i].start = new Date(slots[i].end+'Z');
      }         
      //setApiEvents(slots);
      //console.log("apiEvents v handleCalendarRangeChange")
      //console.log(apiEvents)
      setHolidays(from_date, to_date, slots)
    });
  }

    // resize funguje UPLNE stejne, jako Move.
  const handleCalendarResizeEvents = async ({event, start, end}) => {
      //console.log("Event resize");
      const idx = apiEvents.indexOf(event)   // v apiEvents jsou vsechny eventy pro danny den, vracene z api. 
      //console.log(idx);
      // tady uz teda mam ten updatovany event. Start a end se zmenily. 
      // Budu muset updatovat tento event, tedy ho soupnout zpatky do db. To ale delam vsechno v te vrchni komponente. 
      const updatedEvent = { ...event, start, end };  // tahle syntaxe je vysvetlena v kurzu Packt. Je to ten spread. tj. vsechny polozky se zachovaji, a zmeni se jen ten start a end.    
      const nextEvents = [...apiEvents];  // zkopiruju vsechny eventy
      nextEvents.splice(idx, 1, updatedEvent); // nahradit 
      // ten puvodni se musi opravdu smazat. Na stejne misto vlozim 
      // ten updatovany, ktery ma ale jiny start a end. 
      // ted bych potreboval posunout ty ostatni o ten casovy rozdil.
      
      setApiEvents(nextEvents); 
      const ev_date = moment(start).toISOString().split('T')[0];
      const ev_start = moment(start).toISOString().split('T')[1].split('.')[0];
      const ev_end = moment(end).toISOString().split('T')[1].split('.')[0];
  
      if (event.id) {
        const surgeryParameters = {
          id: event.id,
          date: ev_date,
          start: ev_start,
          end: ev_end,  
        // nic dalsiho se pri presunu eventu menit nebude, takze to nemusim predavat.    
        }
        // tady kdyz to hodi chybu, tak v konsoli to zatroubi
        // Uncaught (in promise) Error: Request failed with status code 422
        // Pozor, tady je PUT, nikoli post. Postem ty veci vytvarim, putem updatuji.   
        const result = await axios.put('/apisurgery/' + event.id, 
                  surgeryParameters,
        )
        return result;
      } // endof if(event.id)
      return;
  
  }

  // ====================================================================================
  // OK - zjednoduseno
  const updateExistingEvent = async () =>
  {
    // FormData micha dohromady udaje o pacientovi i surgery. Tedy veci pro apisurgery controller
    // a apipatient controller. A pak musim prepsat funkci kontroleru actionUpdate. 
    // Mam stav, ktery mi rika, zda jsem modifikoval nejaky atribut uzivatele a podle toho
    // budu volat dve api volani a ruznymi bodyParameters. 
    // Je to podobne, jako ten postup, kdyz zakladam v prazdnem slotu a kontroluju, jestli uzivatel existuje,
    // nebo je novy, nebo... a podle toho se volaji ruzna api. 

    // Tady byla chyba, ze se tu vubec nepocita se zakladanim noveho pacienta
    // bylo tu jen modifikace stavajiciho pacienta, nebo 
    // 
    // Pripadne se ho pokusi zmodifikovat pres put, ale 
    // ten vyzaduje to id, ktere u noveho pacienta jeste neexistuje. 

    var patient_id; // lokalni promenna. 
    if (FormData.patient_id == "") {
      // jde o noveho pacienta => bude se nejprve zakladat
      // pacient. Pokud se to povede, tak se pak zalozi event. TODO: pridat try
      patient_id = await createNewPatient();
    } 
    else{
      patient_id = FormData.patient_id
    }

    // ============================================
    // Modified patient with other form data first
    // tohle by se podobne jako createNewPatient() mohlo vykopnout
    // do samostatne async funkce. Zlepsila by se tim citelnost(?)
    if (FormData.save_patient) {
      const patientBodyParameters = {
        given_name: FormData.given_name,  
        surname: FormData.surname,
        birth_number: FormData.birth_number,
        mail: FormData.mail,
        phone: FormData.phone,        
        patient_notes: FormData.patient_notes,       
        surgeon_id: FormData.surgeon_id,
        dtype_id: FormData.dtype_id,
        id: FormData.patient_id, 
      }
      axios.put('/apipatient/' + patientBodyParameters.id,
        patientBodyParameters,
      ).then(function (response) {        
        if(response.status == 200) {  // 200 - success. Ty kontroly jsou mozna zbytecny.
          console.log("apipatient returned 200");
        }
      })
  }
    
    // ============================================
    // Save rest of surgery parameters. Beware of situation where 
    // patient is not existing - we filled a new one.     
    const surgeryBodyParameters = {
      date: FormData.date,
      start: FormData.start,
      end: FormData.end,
      surgery_notes: FormData.surgery_notes, 
      surgeon_id: FormData.surgeon_id,
      dtype_id: FormData.dtype_id,
      id: FormData.id, // tohle je id operace. 
      //patient_id: FormData.patient_id,
      patient_id: patient_id,  // nastavuje se vyse pri zakladani, resp modifikaci. 
      gliolan: FormData.gliolan,
      examination_only: FormData.examination_only,
    }
    // tohle spoleha na automatiku v activeControlleru ApisurgeryController
    // updatuje to surgery s danym id. Bez toho, abych musel psat nejake zmeny v kodu
    // ALE neumozni mi to editaci pacienta z modalu. To bych musel napsat 
    // ten kontroler rucne. Podobne, jako mam apisurgery/range???
    // PUT /users/123: update the user 123;
//    axios.put('/apisurgeries/' + bodyParameters.id,
//  Nebo bych zavolal i put pro apipatient, s bodyParameters.patient_id


    axios.put('/apisurgery/' + surgeryBodyParameters.id,
              surgeryBodyParameters,
    ).then(function (response) {
      setShowAddPatientModal(false);
      if(response.status == 200) {  // 200 - success. Ty kontroly jsou mozna zbytecny.
        let from_date = moment(FormData.date).startOf('week').toISOString().split('T')[0]; 
        let to_date = moment(FormData.date).endOf('week').toISOString().split('T')[0];    
        fetchDataFromApi(from_date, to_date);         
      }
    })   
  }

  const createNewPatient = async () => 
  {
    const userParameters = {
      given_name: FormData.given_name,  
      surname: FormData.surname,
      birth_number: FormData.birth_number,
      mail: FormData.mail,
      phone: FormData.phone,        
      patient_notes: FormData.patient_notes,
    }          
    // Ale ten axios post vraci promise
    const result = await axios.post('/apipatient',  // url - tady nesmi byt na konci / - s tim to nefunguje...
               userParameters,  // body
    )
    //console.log(result);
    return result.data.id;
  }

  const createSurgery = async (patient_id) => {
    const surgeryBodyParameters = {
      date: FormData.date,
      start: FormData.start,
      end: FormData.end,
      surgery_notes: FormData.surgery_notes,       
      patient_id: patient_id,
      surgeon_id: "1",  // proxy hodnota, nahradit FormData.surgeon_id? Dopredu tam to cislo operatera narvat nemuzu, nebo ano?
      dtype_id: FormData.dtype_id, 
      gliolan: FormData.gliolan,
      examination_only: FormData.examination_only,
      //dtype_id: "1", 
    }
    // POST = create
    // PUT = update
    //const result = await axios.post('/apisurgeries',  // bez pluralize=false to musi byt takto. 
    const result = await axios.post('/apisurgery',   // tohle nefunguje, pokud neni nastaveno v rules url manageru u teto ruly 'pluralize'=>false
    // tedy ['class' => 'yii\rest\UrlRule', 'controller' => 'apisurgery', 'pluralize'=>false],
    // post jde na Route requested: 'apisurgery', Route to run: apisurgery/index
    // Ale v pripade apisurgeries to funguje spravne, tedy na routu Route requested: 'apisurgery/create', Route to run: apisurgery/create
    // Rozdilu jeste uplne nerozumim, ale zatim to nebudu zkoumat. Je to neco s tim pluralize nastavenim, protoze kontroler se jmenuje ApisurgeryController. 
                surgeryBodyParameters,
    )
    return result;
  }

  // ====================================================================================
  const createNewEvent = async () =>
  {
    // Novy event, musime rozlisit, zda se jedna o existujiciho uzivatele z naseptavace
    // nebo zcela noveho zadaneho do formulare. 
    
    console.log("createNewEvent");
    var patient_id; 
    if (FormData.patient_id == "") {
      // jde o noveho pacienta => bude se nejprve zakladat
      // pacient. Pokud se to povede, tak se pak zalozi event. TODO: pridat try
      patient_id = await createNewPatient();
    } 
    else{
      patient_id = FormData.patient_id
    }
    // v updateExistingEvent volam axios.put('/apisurgery/' + surgeryBodyParameters.id,
    // rovnou z jedne funkce po update pacienta. Tady je to rozdelene, ale asi by to slo rozdelit
    // i tam? Neslo, protoze v create surgery mi teprve vrati
    // to id surgery, se kterym dale pracuju. 
    const response = await createSurgery(patient_id);

    setShowAddPatientModal(false); // tohle uz ma byt podminene statusem toho ajaxu. Pokud to ale neni to then. 
    // v response mam i status a statusText. Takze kdyz je 201 - created, tak mohu udelat ten redraw,
    // jinak bych spis mel vyhodit neco o validaci, nebo pripadnou chybu. 
    if(response.status == 201) {  // 201 - created success
      // Takhle ten update funguje v libovolnem tydnu. To je OK.
      // urcuji podle data modifikovane operace zacatek a konec aktualniho tydne
      let from_date = moment(FormData.date).startOf('week').toISOString().split('T')[0]; 
      var to_date = moment(FormData.date).endOf('week').toISOString().split('T')[0];    
      fetchDataFromApi(from_date, to_date);               
    }
  }


   
   // pokus na prepocitavani zacatku a konce - dynamicke
   // odsouvani existujicich events. Vyresit pote, co bude
   // fungovat spravne obycejny move i s ukladanim 
   // movnuteho/resizovaneho eventu.  
   // =========================================================
   const xxxhandleCalendarMoveEvents = ({ event, start, end }) =>
   {
    
    const Result = apiEvents.find(element => {
      console.log(element);
      console.log("Novy start " + start)
      console.log("Novy end " + end)
      if (start > element.start && start < element.end) {
        console.log("nasel jsem spravny event")
        console.log(apiEvents.indexOf(element));
        //  vim index elementu. Ted bych mel prepocitat vsechny zacatky a konce u elementu nasledujicich,
        // POKUD jsou stejny den. 
        console.log(element.start.getDate());  // tohle mi da den, coz mi staci na porovnani.
        return element;  // timhle skoncim v okamziku, kdy najdu spravny element.

      }
    })
    //console.log("=================>");
    console.log(Result);
   }  

  // Tohle se vola na vstupni strance. Je to neco jako vstupni bod.
  // ne - vola se to tehdy, kdyz se renderuje komponenta. To je trochu neco jinyho.  
  useEffect(() => {
    // proto mi tam asi skace dvakrat ten vstup z App()
    // current week    
    let from_date = moment().startOf('week').toISOString().split('T')[0]; // scope je pouze v blocku {}
    var to_date = moment().endOf('week').toISOString().split('T')[0];  // scope je v cele funkci.
    console.log("useEffect")
    //console.log("from_date: " + from_date);
    //console.log("to_date: " + to_date);
    fetchDataFromApi(from_date, to_date);    // fetchDataFromApi je principialne asynchronni. To znamena, ze setHolidays se provede asi driv, nez fetch dobehne. 
    // tzn tady nemohu volat setHolidays, protze se provedou driv, nez se dokonci to fetchDataFromApi.
    // ale mohu ty setHolidays delat UVNITR toho fetchData then a bude to v  poho
    
  }, []); // prazdna deklarace dependency zajisti, ze se useEffect spusti jen pri mountu komponenty (tj. jen jednou)


  // vlastni rendering
  return (
    <>

      <AddPatientModal
        FormData={FormData}
        isOpen={showAddPatientModal}
        handleClose={handleCloseAddPatientModal}
        handleSubmit={handleSubmitAddPatientModal}
        handleOnChange={handleOnChangeAddPatientModal}
        handleAsyncSelect={handleAsyncSelect} // tohle se musi predat Z modalu DO AsyncSelectu.
        handleAsyncSelectDiagnosis={handleAsyncSelectDiagnosis}
        handleCheckbox={handleCheckbox} //
        fetchDataFromApi={fetchDataFromApi}
      />

        <DragAndDropCalendar
          selectable
          resizable
          popup={true}
          step={settings.calendarStep} 
          timeslots={settings.calendarTimeslots} 
          localizer={localizer}
          dayLayoutAlgorithm={dayLayoutAlgorithm}
          //dayPropGetter={this.customDayPropGetter}  // tohle jsem puvodne zamyslel pro zvyrazneni svatku.
          // nebo by to slo i takhle
          // https://codepen.io/gulivertx/pen/gOMpbXy

          eventPropGetter={eventRenderProp} // Ale nakonec jsem pouzil event a background event.
          // TODO: nicmene ten PropGetter by se hodil i pro ten background. Takto tam mam classu rbc-event--undefined.
          // stejne jako u toho eventPropGetter bych tam mohl dat treba rbc-event--holiday a bylo by to v css uplne jasny.
          events={apiEvents} //           
          // Potreboval bych tedy metodu, ktera zapise do databaze zmenene sloty, kterym se zmenil zacatek a konec pri vlozeni noveho eventu
          // pripadne presunu existujiciho. Pri presunu se musi zaktualizovat jak ten presouvany, tak ty ostatni.
          // Update techto events pak tedy musim delat
          //   - updatovat samotny event
          //   - seznam events, ktere jsem zmenil. Ty nove dostanou svoje id v databasi. Ty predgenerovane tam zadny id nemaji.
          //   - to se
          backgroundEvents={backgroundEvents}
          //onEventDrop={this.moveEvent}
          onEventDrop={handleCalendarMoveEvents}
          onEventResize={handleCalendarResizeEvents}                    
          //culture='cs'
          onDoubleClickEvent={doubleClicker}
          //onSelectSlot={this.slotClicker} // klik do prazdneho slotu, vyvolat novy modal, pri ukladani zaktualizovat sloty, ktere nasleduji za mnou.
          onSelectSlot={handleShowAddPatientModal}
          // Slot je neco jinyho... Je to casovy slot.
          // Event je to modre pole, ktere v mem pojeti definuje operaci.
          onSelectEvent={handleShowAddPatientModal}
          date={currentDate}
          onNavigate={handleNavigate}
          setCurrentDate={setCurrentDate}
          // work_week musi byt povoleny, aby sel pouzit nize jko defaultView
          // kdyz tam bude vic views, tak se pak zobrazi jako tlacitka na prehazovani pohledu
          // ten defaultView dole pouze rika, ktery z nich se ma zobrazit jako vychozi
          //
          // Ty hodnoty Views.WEEK se resolvuji na textove retezce, muze tam tedy byt to, nebo to.
          //views={{work_week:true}}
          views={["week"]}
          // tady to chci nastavit programove pomoci settings, ale nefunguje mi to
          defaultView={"week"} // jaky ma byt zakladni pohled - jestli den, mesic, nebo tyden. Ja mam tyden.
          //HOPSEM - TADY je potreba pouzit novy setState,
          // kdo to nastavuje? to state.selectedDay?
          // Ale ono to tu neni potreba, funguje to i bez toho.
          //date={state.selectedDay}

          // tohle nastava, jen kdyz menim datum
          // takze jeste se musi podobne osetrit onView
          //onNavigate={}
        
          /*
        onNavigate={(date, view, action) => {
          //this.setState({ selectedDate: date });
          //console.log(date);
          console.log("onNavigate fired");
          console.log("Mel bych mit rozsah ted dat nize...")
          console.log(dates.firstVisibleDay(date), dates.lastVisibleDay(date));
        }}
        */
          onView={(view) => {
            console.log("onView fired");
            console.log("#### onView");
            console.log("#### view=", view);
            //this.setState({currentView: view});
          }}

          // Callback fired when the visible date range changes. Returns an Array of dates or an object with start and end dates for BUILTIN views.
          // Custom views may return something different.
          // https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props--on-range-change
          // To znamena, ze to pali pri zmenach dopredu/dozadu/today, ale i pri zmene pohledu - tyden/mesic/rok
          //
          // Zrada je v tom, ze kdyz budu chtit defivat vlastni stav Date, tak budu muset prepsat i ovladani tech tlacitek dopredu/dozadu/today
          // protoze ty pouzivaji ten stav Date. Asi. Date je kontrolovany funkci onNavigae
          onRangeChange={handleCalendarRangeChange} 

          onShowMore={(events, date) =>
            this.setState({ showModal: true, events })
          }
          components={{
            event: MyEvent,
            // v props toolbaru se defaultne predava date, label, localizer, onNavigate, onView, view, views.  
            toolbar: props => ( // predavam anonymni funkci, ktera vraci komponentu customToolbar, ale s props rozsirenymi o to, co potrebuju ja. 
              <CustomToolbar {...props} onNavigate={handleNavigate} fetchDataFromApi={fetchDataFromApi} />
            ),
          }}

          //defaultDate={new Date()}  // today
          // TODO: dle nastaveni prepinat cs/eng. 
          messages={{'today': "Dnes",
                   "previous":'Předchozí', 
                   "next":"Následující",
                   'month': 'měsíc',
                   'week': 'týden',
                   'day' : 'den',
                   'agenda': 'agenda',
                   'date' : 'Datum',
                   'time' : 'Čas',
                   'event' : 'Událost',
                  }} 
        />
    </>
  );
}

// Runtime type checking for React props and similar objects.
DndCalendar.propTypes = {
  // localizer: PropTypes.instanceOf(DateLocalizer),
};

export default DndCalendar;
