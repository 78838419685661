import React from 'react';
import './events.css';

const MyEvent = ({ event }) => 
{
  //const customClass = `.rbc-day-slot .rbc-event--${event.type}`;

  return(
    <div className="event">
      <div className="event-content patientname" > 
        {event.given_name} {event.surname}
      </div>
      <div className="event-content birth_number">
        {event.birth_number}
      </div>
      <div className="event-content patient_notes">
        {event.patient_notes}
      </div>
      <div className="event-content surgery_notes">
        {event.surgery_notes}
      </div>
      <div className="event-content diagnosis">
        {event.diagnosis}
      </div>

  </div>
  );
};

export default MyEvent;